import(/* webpackMode: "eager", webpackExports: ["LoginForm"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/app/[locale]/auth/login/LoginForm.tsx");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/operator/src/icons/DeltaGreenCopilotLogoHorizontalIcon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.1.6_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.1.6_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.1.6_react@19.0.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Spacer"] */ "/builds/deltagreen/delta-monorepo/packages/reactLibrary/dist/index.js");
